<template>
  <div class="box box3">
    <div class="box-title">最新收运</div>
    <div class="list">
      <div v-if="listData.length != 0">
        <div class="list-item" v-for="item in listData" :key="item.id">
          <div class="flex">
            <div><i class="el-icon-timer"></i>{{ item.collectTime.split(' ')[0] }}</div>
            <div>
              {{ item.employeeName }}
              <span class="margin-l-20"
                >{{ item.collectType }}：<span class="numcolor">{{
                  item.weight
                }}</span
                >kg</span
              >
            </div>
          </div>
          <el-divider></el-divider>
          <div>收运单位：{{ item.collectorName }}</div>
        </div>
      </div>
      <div class="empty-text" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { newEst } from "@/api/index";
export default {
  data() {
    return {
      areaCode: "",
      listData: [],
      time_getalarm: ""
    };
  },
  methods: {
    getNewest(areaCode) {
      newEst(areaCode).then(res => {
        // console.log(res);
        this.listData = res.data;
      });
    }
  },
  mounted() {
    this.getNewest(this.areaCode);
    this.time_getalarm = setInterval(() => {
      this.getNewest(this.areaCode);
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.time_getalarm);
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.getNewest(this.areaCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box3 {
  height: calc(40vh - 85px);
}
.list {
  height: calc(40vh - 135px);
  overflow: hidden;
  // border: 1px solid #ff0000;
}
.list-item {
  background: rgba(33, 51, 93, 0.4);
  // border: 1px solid #3a518a;
  padding: 10px;
  height: calc((40vh - 155px) / 3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 5px;
}
.list-item:last-child {
  margin-bottom: 0;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-divider {
  margin: 0;
  background: rgba($color: #ffffff, $alpha: 0.06);
}
.numcolor {
  color: #ffa169;
}
.margin-l-20 {
  margin-left: 20px;
}
.empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.el-icon-timer {
  color: rgba(0, 244, 255, 1);
  margin-right: 5px;
}
</style>
