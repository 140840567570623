<template>
  <div id="content-right">
    <oil-num />
    <WarningList />
    <Collector />
  </div>
</template>

<script>
import oilNum from "@/views/index/contentRight/components/oilNum";
import WarningList from "@/views/index/contentRight/components/warningList";
import Collector from "@/views/index/contentRight/components/collector";

export default {
  components: { oilNum, WarningList, Collector },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
#content-right {
  width: 25%;
  float: right;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 1024;
  // border: 1px solid #f0f;
  padding-right: 10px;
  padding-bottom: 10px;
}
</style>
