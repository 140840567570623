<template>
  <div class="content">
    <Map />
    <ContentLeft />
    <ContentBottom />
    <ContentRight />
   
  </div>
</template>

<script>
import Map from "@/views/index/map/index";
import ContentLeft from "@/views/index/contentLeft/index";
import ContentRight from "@/views/index/contentRight/index";
import ContentBottom from "@/views/index/ContentBottom";
export default {
  components: {
    Map,
    ContentLeft,
    ContentRight,
    ContentBottom
  }
};
</script>
