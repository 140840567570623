<template>
  <div>
    <div class="map-content">
      <div class="map-flex header">
        <div class="title" @click="shopInfo">{{ infoData.shortName }}</div>
        <div @click="close()" class="close-btn">
          <img src="@/assets/images/map/close.png" alt="" />
        </div>
      </div>
      <div class="map-main">
        <div class="map-img" @click="shopInfo">
          <div class="map-tag">{{ infoData.type }}</div>
          <img :src="url + infoData.image" v-if="infoData.image != ''" />
          <img src="@/assets/images/map/default_img.png" v-else />
        </div>
        <div style="margin-top:10px">
          <div style="display:flex;align-items: center;padding:8px 0">
            <img
              src="@/assets/images/map/dianhua.png"
              alt=""
              style="margin-right:10px"
            />
            {{ infoData.phone == null ? "-" : infoData.phone }}
          </div>
          <div style="display:flex;align-items: center;padding:8px 0">
            <img
              src="@/assets/images/map/dizhi.png"
              style="margin-right:10px"
              alt=""
            />
            {{ infoData.address == null ? "-" : infoData.address }}
          </div>
        </div>
        <div class="map-line"></div>
        <div class="map-flex-t lineHeight">
          <div>
            <div>
              最近收运时间
              <span style="margin-left:10px">{{
                infoData.collectInfo.collectDate == null
                  ? "-"
                  : infoData.collectInfo.collectDate
              }}</span>
            </div>
            <div>
              收运单位：{{
                infoData.collectInfo.collectorName == null
                  ? "-"
                  : infoData.collectInfo.collectorName
              }}
            </div>
            <div>
              收运人员：{{
                infoData.collectInfo.employeeName == null
                  ? "-"
                  : infoData.collectInfo.employeeName
              }}
            </div>
            <div>
              联系电话：{{
                infoData.collectInfo.employeePhone == null
                  ? "-"
                  : infoData.collectInfo.employeePhone
              }}
            </div>
          </div>
          <div style="text-align: right;">
            <div class="num">
              {{
                infoData.collectInfo.collectWeight == null
                  ? 0
                  : infoData.collectInfo.collectWeight
              }}kg
              <span
                class="spanType"
                v-if="infoData.collectInfo.collectType != null"
                >{{ infoData.collectInfo.collectType }}</span
              >
            </div>
            <div class="defult-img">
              <img
                :src="infoData.collectInfo.avatar"
                v-if="infoData.collectInfo.avata != null"
              />
              <img src="@/assets/images/map/default_user.png" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      infoWindow: null,
      overlay: null,
      id: "",
      infoData: {
        name: null,
        type: null,
        phone: null,
        image: null,
        address: null,
        collectInfo: {}
      },
      url: process.env.VUE_APP_BASE_API
    };
  },
  methods: {
    initialize(e) {
      this.overlay = e.overlay;
      this.infoWindow = e.infoWindow;
      this.id = e.id;
      this.infoData = e.infoData;
    },
    close() {
      this.infoWindow.close();
      this.$parent.setView();
    },
    shopInfo() {
      this.$router.push({ path: "/merchantsDetails", query: { id: this.id } });
    }
  }
};
</script>
<style lang="scss" scoped>
.map-content {
  background: rgba(3, 14, 36, 0.8);
  border: rgba(49, 248, 255, 0.8) 1px solid;
  color: #fff;
  border-radius: 10px;
}
.map-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
}
.title {
  color: #64fdff;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
}
.map-main {
  padding: 10px;
}
.map-img {
  position: relative;
  width: 360px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.map-tag {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 1.125rem;
  color: rgba(236, 182, 37, 1);
  background: rgba(19, 27, 56, 0.95);
}
.map-line {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
}
.defult-img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100%;
  }
}
.close-btn {
  cursor: pointer;
  width: 2.222vh;
  // height: 2.222vh;
  margin-left: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.map-flex-t {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.lineHeight {
  line-height: 2;
  font-size: 0.875rem;
}
.num {
  color: rgba(49, 248, 255, 1);
  margin-bottom: 10px;
}
.spanType {
  background: #e28916;
  border: 1px solid #e69a36;
  border-radius: 3px;
  padding: 2px;
  color: #fff;
}
</style>
