var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box box-bottom box2"},[_c('div',{staticClass:"box-title"},[_vm._v("智能预警")]),_vm._m(0),(_vm.listData.length != 0)?_c('div',[_c('vue-seamless-scroll',{staticClass:"list",attrs:{"data":_vm.listData,"class-option":_vm.defaultOption}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"table-list-item",staticStyle:{"height":"42px","line-height":"42px"}},[_c('div',{staticClass:"name",staticStyle:{"width":"45%"}},[_vm._v(_vm._s(item.merchantName))]),_c('div',{staticStyle:{"width":"45%"},domProps:{"innerHTML":_vm._s(item.message)}}),_c('div',{staticClass:"level",staticStyle:{"width":"25px","text-align":"center","height":"25px","line-height":"25px"},style:({
            color:
              item.alarmLevel == 1
                ? '#FFDC18'
                : item.alarmLevel == 2
                ? '#FE712C'
                : '#FF4343'
          })},[_vm._v(" "+_vm._s(item.alarmLevel == 1 ? "低" : item.alarmLevel == 2 ? "中" : "高")+" ")])])}),0)],1):_c('div',{staticClass:"empty-text"},[_vm._v("暂无数据")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-flex",staticStyle:{"color":"#7fd0fe","margin-bottom":"0.926vh"}},[_c('div',{staticStyle:{"width":"45%"}},[_vm._v("商家名称")]),_c('div',{staticStyle:{"width":"45%"}},[_vm._v("预警信息")]),_c('div',{staticStyle:{"width":"10%","text-align":"center"}},[_vm._v("等级")])])}]

export { render, staticRenderFns }