<template>
  <div class="box box2">
    <div class="box-title">商家区域占比</div>
    <div v-if="data.length != 0">
      <div id="area-chart"></div>
    </div>
    <div class="empty-text" v-if="data.length == 0">暂无数据</div>
  </div>
</template>

<script>
import { areaOf } from "@/api/index";
export default {
  data() {
    return {
      areaCode: "",
      data: []
    };
  },
  mounted() {
    this.getAreaOf(this.areaCode);
  },
  methods: {
    getAreaOf(areaCode) {
      areaOf(areaCode).then(res => {
        // console.log(res);
        this.data = [];
        if (res.data.length != 0) {
          res.data.map(item => {
            this.data.push({ value: item.number, name: item.area });
          });
          this.$nextTick(() => {
            this.areaChart();
          });
        }
      });
    },
    areaChart() {
      let myChart = this.$echarts.init(document.getElementById("area-chart"));

      let option = {
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "商家区域占比",
            type: "pie",
            radius: ["30%", "70%"],
            color: [
              "#fc962c",
              "#d83472",
              "#0F9AF8",
              "#2B63D5",
              "#2039C3",
              "#2ECACE",
              "#6F81DA"
            ],
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            label: {
              show: true,
              formatter: "{b}: {d}%",
              fontStyle: "normal",
              color: "#fff",
              fontSize: 14
            },
            itemStyle: {
              borderWidth: 3,
              borderColor: "rgba(11,22,51,0.8)"
            }
          }
        ]
      };
      // 绘制图表
      myChart.setOption(option);

      window.addEventListener("resize", () => {
        myChart.resize();
      });
    }
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.getAreaOf(this.areaCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box2 {
  height: calc(32vh - 95px);
  margin-bottom: 20px;
}
#area-chart {
  width: 100%;
  height: calc(32vh - 145px);
}
.empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
