<template>
  <div class="box box-bottom box1">
    <div class="box-title">商家总览</div>
    <div v-if="data.length != 0">
      <div id="shop-chart"></div>
    </div>

    <div class="empty-text" v-if="data.length == 0">暂无数据</div>
  </div>
</template>

<script>
import { merchant } from "@/api/index";
export default {
  data() {
    return {
      areaCode: "",
      data: []
    };
  },
  mounted() {
    this.getShop(this.areaCode);
  },
  methods: {
    getShop(areaCode) {
      merchant(areaCode).then(res => {
        // console.log(res);
        this.data = [];
        if (res.data.length != 0) {
          res.data.map(item => {
            this.data.push({ value: item.number, name: item.type });
          });
          this.$nextTick(() => {
            this.getShop_shopChart();
          });
        }
      });
    },
    getShop_shopChart() {
      let myChart = this.$echarts.init(document.getElementById("shop-chart"));
      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: "#fff"
          },
          icon: "circle"
        },
        series: [
          {
            name: "商家总览",
            type: "pie",
            radius: ["30%", "80%"],
            center: ["55%", "50%"],
            label: {
              show: true,
              formatter: "{d}%",
              textStyle: {
                color: "#fff"
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            data: this.data
            // [
            //   { value: 1048, name: "搜索引擎" },
            //   { value: 735, name: "直接访问" },
            //   { value: 580, name: "邮件营销" },
            //   { value: 484, name: "联盟广告" },
            //   { value: 300, name: "视频d告" },
            //   { value: 484, name: "联盟告" },
            //   { value: 300, name: "视频告" },
            //   { value: 300, name: "视频2告" }
            // ]
          }
        ]
      };
      // 绘制图表
      myChart.setOption(option);

      window.addEventListener("resize", () => {
        myChart.resize();
      });
    }
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.getShop(this.areaCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box1 {
  height: calc(32vh - 95px);
}
#shop-chart {
  width: 100%;
  height: calc(32vh - 13.426vh);
}
.empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
