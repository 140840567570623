<template>
  <div class="box3">
    <div class="title">设备情况</div>
    <div v-if="data.allCount != 0">
      <div id="eqt-chart" style="width: 100%; height: calc(16vh - 40px)"></div>
    </div>
    <div class="empty-text" v-if="data.allCount == 0">暂无数据</div>
  </div>
</template>

<script>
import { equipment } from "@/api/index";
export default {
  data() {
    return {
      data: {
        areaCode: "",
        allCount: 0,
        offlineCount: 0,
        onlineCount: 0,
        timer1: null
      }
    };
  },
  mounted() {
    this.getEqt(this.areaCode);
    this.timer1 = setInterval(() => {
      this.getEqt(this.areaCode);
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
  },
  methods: {
    getEqt(areaCode) {
      equipment(areaCode).then(res => {
        // console.log(res);
        this.data = res.data;
        if (res.data.allCount != 0) {
          this.$nextTick(() => {
            this.eqtChart();
          });
        }
      });
    },
    eqtChart() {
      let myChart = this.$echarts.init(document.getElementById("eqt-chart"));
      let option = {
        title: [
          {
            text: "设备总数",
            left: "18%",
            top: "80%",
            textAlign: "center",
            textStyle: {
              fontWeight: "normal",
              fontSize: "16",
              // color: colors[index][0],
              color: "#fff",
              textAlign: "center"
            }
          },
          {
            text: "在线设备",
            left: "49%",
            top: "80%",
            textAlign: "center",
            textStyle: {
              fontWeight: "normal",
              fontSize: "16",
              // color: colors[index][0],
              color: "#fff",
              textAlign: "center"
            }
          },
          {
            text: "离线设备",
            left: "79%",
            top: "80%",
            textAlign: "center",
            textStyle: {
              fontWeight: "normal",
              fontSize: "16",
              // color: colors[index][0],
              color: "#fff",
              textAlign: "center"
            }
          }
        ],
        series: [
          {
            name: "设备总数",
            type: "pie",
            clockWise: false,
            radius: ["55%", "70%"],
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#fe5577" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#ffc8a8" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            center: ["20%", "40%"],
            data: [
              {
                value: this.data.allCount,
                label: {
                  normal: {
                    formatter: function(params) {
                      return params.value;
                    },
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#fff"
                    }
                  }
                }
              },
              {
                value: 0,
                name: "invisible",
                itemStyle: {
                  normal: {
                    color: "#454961"
                  }
                }
              }
            ]
          },
          {
            name: "在线设备",
            type: "pie",
            clockWise: false,
            radius: ["55%", "70%"],
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#616eff" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#0cdff4" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            center: ["50%", "40%"],
            data: [
              {
                value: this.data.onlineCount,
                label: {
                  normal: {
                    formatter: function(params) {
                      return params.value;
                    },
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#fff"
                    }
                  }
                }
              },
              {
                value: this.data.allCount - this.data.onlineCount,
                name: "invisible",
                itemStyle: {
                  normal: {
                    color: "#454961"
                  }
                }
              }
            ]
          },
          {
            name: "离线设备",
            type: "pie",
            clockWise: true,
            radius: ["55%", "70%"],
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#8a44e4" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#fd6c6e" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            center: ["80%", "40%"],
            data: [
              {
                value: this.data.offlineCount,
                label: {
                  normal: {
                    formatter: function(params) {
                      return params.value;
                    },
                    position: "center",
                    show: true,
                    textStyle: {
                      fontSize: "20",
                      fontWeight: "bold",
                      color: "#fff"
                    }
                  }
                }
              },
              {
                value: this.data.allCount - this.data.offlineCount,
                itemStyle: {
                  normal: {
                    color: "#454961"
                  }
                }
              }
            ]
          }
        ]
      };
      // 绘制图表
      myChart.setOption(option);

      window.addEventListener("resize", () => {
        myChart.resize();
      });
    }
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.getEqt(this.areaCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box3 {
  height: 16vh;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #27c3c9;
  background: rgba($color: #0b1633, $alpha: 0.8);
  box-shadow: 0 0 10px #004fb3 inset;
  position: relative;
  padding: 10px;
  .title {
    color: #30d8fc;
    // background: brown;
    height: 20px;
    line-height: 20px;
  }
}
.empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
