<template>
  <div id="content-left">
    <OilNum />
    <ShopNum />
    <Area />
    <Equipment />
  </div>
</template>

<script>
import OilNum from "@/views/index/contentLeft/components/oilNum";
import ShopNum from "@/views/index/contentLeft/components/shopNum";
import Area from "@/views/index/contentLeft/components/Area";
import Equipment from "@/views/index/contentLeft/components/equipment";
export default {
  components: { OilNum, ShopNum, Area, Equipment },
  data() {
    return {};
  },
  mounted() {
    // this.timeShop = setInterval(this.shopType, 1000 * 60);
    // this.timeEq = setInterval(this.equipment, 1000 * 60);
    // this.timeAlarm = setInterval(this.alarm, 1000 * 60 * 10);
  }
  // beforeDestroy() {
  //   clearInterval(this.timeShop);
  //   clearInterval(this.timeEq);
  //   clearInterval(this.timeAlarm);
  // },
};
</script>
<style lang="scss" scoped>
#content-left {
  width: 25%;
  position: absolute;
  left: 0;
  top: 100px;
  // border: 1px solid #f0f;
  z-index: 1024;
  padding-left: 10px;
  padding-bottom: 10px;
}
</style>
