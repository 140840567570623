import request from "@/utils/request";

// 油/渣监控
export function outPut(areaCode) {
  return request({
    url: "/bi/merchant/output/" + areaCode,
    method: "GET"
  });
}

// 商家总览
export function merchant(areaCode, query) {
  return request({
    url: "/bi/merchant/type/" + areaCode,
    method: "GET",
    params: query
  });
}

// 区域占比
export function areaOf(areaCode, query) {
  return request({
    url: "/bi/merchant/area/" + areaCode,
    method: "GET",
    params: query
  });
}

// 设备数量
export function equipment(areaCode) {
  return request({
    url: "/bi/equipment/" + areaCode,
    method: "GET"
  });
}

// 油/渣收运
export function collectCount(areaCode) {
  return request({
    url: "/bi/collect/stat/" + areaCode,
    method: "GET"
  });
}

// 智能预警
export function alarm(areaCode) {
  return request({
    url: "/bi/merchant/alarm/" + areaCode,
    method: "GET"
  });
}

// 最新收运
export function newEst(areaCode) {
  return request({
    url: "/bi/collect/newest/" + areaCode,
    method: "GET"
  });
}

// 地图
export function coorDinate(areaCode) {
  return request({
    url: "/bi/merchant/coordinate/" + areaCode,
    method: "GET"
  });
}

// 总数
export function flow(areaCode) {
  return request({
    url: "/bi/flow/" + areaCode,
    method: "GET"
  });
}

// 地图商家信息窗体详情
export function merchantInfo(merchantCode) {
  return request({
    url: "/bi/merchant/info/" + merchantCode,
    method: "GET"
  });
}
