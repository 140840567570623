<template>
  <div id="map-box">
    <div id="container"></div>
    <InfoWin ref="infoWindowComponent" v-show="isShowWindow" />
  </div>
</template>

<script>
import InfoWin from "./infoWindow";

var map;
var infoWindow;
import { areaSelect } from "@/api/login";
import { coorDinate, merchantInfo } from "@/api/index";
export default {
  components: { InfoWin },
  data() {
    return {
      areaCode: "",
      isShowWindow: false,
      lnglats: []
      // timerMap: null
    };
  },
  mounted() {
    this.creatMap();
    
    // this.timerMap = setInterval(() => {
    //   this.getData(this.areaCode);
    // }, 6000);
  },
  destroyed() {
    // clearInterval(this.timerMap);
    map.destroy();
  },
  methods: {
    // 创建地图
    creatMap() {
      map = new window.AMap.Map("container", {
        zoom: 8,
        // center: [116.43, 39.92],
        resizeEnable: true,
        viewMode: "3D",
        pitch: 50,
        rotation: 20,
        expandZoomRange: true,
        // showBuildingBlock: true,
        // showIndoorMap: true,
        // showLabel: false,
        // features: ["bg", "point", "road"],
        mapStyle: "amap://styles/darkblue" //设置地图的显示样式
      });
      let _this = this;
      map.on("complete", function() {
        // 地图图块加载完成后触发

        areaSelect('').then(response => {
            const nodes = response.data.map(item => ({
              value: item.areaCode,
              label: item.areaName,
              leaf: item.leaf,
              disabled: item.disabled
            }));
            _this.areaCode=nodes[0].value
            _this.getData(_this.areaCode);
         });
     



      });
    },
    getData(areaCode) {
      // 清除所有覆盖物
      map.clearMap();
      areaCode=areaCode?areaCode:''
      coorDinate(areaCode).then(res => {
// console.log(res,'0000000000000000000000')

        // console.log(res);
        res.data.map(item => {
          let _this = this

          item.lat=[ item.longitude, item.lat ]

          // let a=item.lat
          // item.lat=item.longitude
          // item.longitude=a


          // item.lng=item.lat
          // item.lat=
          
          _this.pointAdd(item)
    
          // window.AMap.convertFrom([item.longitude, item.lat], "baidu", function(
          //   status,
          //   result
          // ) {
            
          //   if (result.info === "ok") {
          //     item.lat = result.locations.toString().split(",");
          //     _this.pointAdd(item);

          //     // _this.pointAdd2(item);
          //   }
          // });
        });
      });
    },

    pointAdd(item) {
    
      var lnglat = item.lat;
      var marker = [];
      var icon = [];

      switch (item.bizType) {
        case 1:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39), // 图标尺寸
            image: require("@/assets/images/map/map_icon1.png"), // Icon的图像
            // imageOffset: new window.AMap.Pixel(-10, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new window.AMap.Size(34, 39) // 根据所设置的大小拉伸或压缩图片
          });
          break;
        case 2:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon2.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
        case 3:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon3.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
        case 4:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon4.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
        case 5:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon5.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
        case 6:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon6.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
        case 7:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon7.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
        default:
          icon = new window.AMap.Icon({
            size: new window.AMap.Size(34, 39),
            image: require("@/assets/images/map/map_icon8.png"),
            imageSize: new window.AMap.Size(34, 39)
          });
          break;
      }
      marker = new window.AMap.Marker({
        position: new window.AMap.LngLat(lnglat[0], lnglat[1]),
        icon: icon,
        offset: new window.AMap.Pixel(-17, -39),
        extData: {
          id: item.merchantCode
        }
      });
      if (item.beat) {
        marker.setAnimation("AMAP_ANIMATION_BOUNCE");
      }
      marker.on("click", this.showInfoM);
      // 将点添加到地图
      map.add(marker);
      map.setFitView(); //地图自适应
    },
    pointAdd2(item) {
      // var markers = [];
      var lnglat = item.lat;
      var marker = [];

      marker = new window.AMap.Marker({
        position: new window.AMap.LngLat(lnglat[0], lnglat[1]),
        // icon: icon,
        // offset: new window.AMap.Pixel(-22, -50),
        extData: {
          id: item.id
        }
      });
      if (item.setAnimation) {
        marker.setAnimation("AMAP_ANIMATION_BOUNCE");
      }
      marker.on("click", this.showInfoM);
      // markers.push(marker);
      // });
      // 将点添加到地图
      map.add(marker);
      map.setFitView(); //地图自适应
    },
    // 获取点参数id    构建信息窗体
    showInfoM(e) {
      this.isShowWindow = true;
      let that = this;
      if (that.isShowWindow) {
        infoWindow = new window.AMap.InfoWindow({
          offset: new window.AMap.Pixel(0, -50),
          showShadow: true,
          isCustom: true, //使用自定义窗体
          content: that.$refs.infoWindowComponent.$el
        });
        infoWindow.open(map, e.target.getPosition());
        // console.log(e.target.getExtData().id);
        merchantInfo(e.target.getExtData().id).then(res => {
          console.log(res);
          var infoData = res.data;
          // console.log(infoData);
          that.$refs.infoWindowComponent.initialize({
            overlay: e.target,
            infoWindow: infoWindow,
            id: e.target.getExtData().id,
            infoData: infoData
          });
        });
        map.setZoomAndCenter(18, [
          e.target.getPosition().lng,
          e.target.getPosition().lat
        ]);
        map.panBy(0, 100);
      }
    },
    setView() {
      this.isShowWindow = !this.isShowWindow;
      // map.setFitView(); //地图自适应
    }
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.getData(this.areaCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#map-box {
  width: 100%;
  height: 100vh;
}
#container {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss"></style>
