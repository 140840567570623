<template>
  <div class="box box-bottom box-height">
    <div class="box-title">油 / 渣收运</div>
    <div class="flex">
      <div class="flex-col">
        <div class="num-item">
          <div class="num">
            <countTo
              :startVal="Val.num1.startVal"
              :endVal="Val.num1.endVal*1 >1000? (Val.num1.endVal/1000):Val.num1.endVal "
              :duration="duration"
              :decimals="decimals"
            ></countTo>
            <span class="num-text">{{ Val.num1.endVal*1 >1000?'吨':'kg' }}</span>
          </div>
          <div>日收油量</div>
        </div>
        <div class="num-item">
          <div class="num">
            <countTo
              :startVal="Val.num2.startVal"
              :endVal="Val.num2.endVal*1 >1000? (Val.num2.endVal/1000):Val.num2.endVal "
              :duration="duration"
              :decimals="decimals"
            ></countTo>
            <span class="num-text">{{ Val.num2.endVal*1 >1000?'吨':'kg' }}</span>
          </div>
          <div>月收油量</div>
        </div>
        <div class="num-item">
          <div class="num">
            <countTo
              :startVal="Val.num3.startVal"
              :endVal="Val.num3.endVal*1 >1000? (Val.num3.endVal/1000):Val.num3.endVal "
              :duration="duration"
              :decimals="decimals"
            ></countTo>
            <span class="num-text">{{ Val.num3.endVal*1 >1000?'吨':'kg' }}</span>
          </div>
          <div>总收油量</div>
        </div>
      </div>
      <div class="flex-col">
        <div class="num-item">
          <div class="num">
            <countTo
              :startVal="Val.num4.startVal"
              :endVal="Val.num4.endVal*1 >1000? (Val.num4.endVal/1000):Val.num4.endVal "
              :duration="duration"
              :decimals="decimals"
            ></countTo>
            <span class="num-text">{{ Val.num4.endVal*1 >1000?'吨':'kg' }}</span>
          </div>
          <div>日收渣量</div>
        </div>
        <div class="num-item">
          <div class="num">
            <countTo
              :startVal="Val.num5.startVal"
              :endVal="Val.num5.endVal*1 >1000? (Val.num5.endVal/1000):Val.num5.endVal "
              :duration="duration"
              :decimals="decimals"
            ></countTo>
            <span class="num-text">{{ Val.num5.endVal*1 >1000?'吨':'kg' }}</span>
          </div>
          <div>月收渣量</div>
        </div>
        <div class="num-item">
          <div class="num">
            <countTo
              :startVal="Val.num6.startVal"
              :endVal="Val.num6.endVal*1 >1000? (Val.num6.endVal/1000):Val.num6.endVal "
              :duration="duration"
              :decimals="decimals"
            ></countTo>
            <span class="num-text">{{ Val.num6.endVal*1 >1000?'吨':'kg' }}</span>
          </div>
          <div>总收渣量</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import { collectCount } from "@/api/index";
export default {
  components: { countTo },
  data() {
    return {
      areaCode: "",
      // 抽油统计
      decimals: 2,
      duration: 5000,
      Val: {
        num1: {
          startVal: 0, // 初始值
          endVal: 0 // 最终值
        },
        num2: {
          startVal: 0, // 初始值
          endVal: 0 // 最终值
        },
        num3: {
          startVal: 0, // 初始值
          endVal: 0 // 最终值
        },
        num4: {
          startVal: 0, // 初始值
          endVal: 0 // 最终值
        },
        num5: {
          startVal: 0, // 初始值
          endVal: 0 // 最终值
        },
        num6: {
          startVal: 0, // 初始值
          endVal: 0 // 最终值
        }
      },
      // 定时器
      timer: ""
    };
  },
  mounted() {
    this.getCount(this.areaCode);
    this.timer = setInterval(() => {
      this.get();
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getCount(areaCode) {
      collectCount(areaCode).then(res => {
        res.data.map(item => {
          if (item.collectType === 1) {
            this.Val.num1.endVal = Number(item.todayWeight);
            this.Val.num2.endVal = Number(item.monthWeight);
            this.Val.num3.endVal = Number(item.allWeight);
          } else if (item.collectType === 2) {
            this.Val.num4.endVal = Number(item.todayWeight);
            this.Val.num5.endVal = Number(item.monthWeight);
            this.Val.num6.endVal = Number(item.allWeight);
          }
        });
      });
    },
    get() {
      this.Val.num1.startVal = this.Val.num1.endVal;
      this.Val.num2.startVal = this.Val.num2.endVal;
      this.Val.num3.startVal = this.Val.num3.endVal;
      this.Val.num4.startVal = this.Val.num4.endVal;
      this.Val.num5.startVal = this.Val.num5.endVal;
      this.Val.num6.startVal = this.Val.num6.endVal;
      this.getCount(this.areaCode);
    }
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.get();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
.flex-col {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .num {
    color: #64ff93;
    font-size: 1.5rem;
    margin-bottom: 10px;
    .num-text {
      font-size: 0.875rem;
    }
  }
}
.num-item {
  text-align: center;
  width: 100%;
  font-size: 0.875rem;
}
.box-height {
  height: 20vh;
}
</style>
