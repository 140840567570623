<template>
  <div class="box box-bottom box2">
    <div class="box-title">智能预警</div>
    <div class="table-flex" style="color: #7fd0fe;margin-bottom: 0.926vh;">
      <div style="width: 45%">商家名称</div>
      <div style="width: 45%">预警信息</div>
      <div style="width: 10%; text-align: center">等级</div>
    </div>
    <div v-if="listData.length != 0">
      <vue-seamless-scroll
        :data="listData"
        :class-option="defaultOption"
        class="list"
      >
        <div
          class="table-list-item"
          v-for="(item, index) in listData"
          :key="index"
          style="height: 42px; line-height: 42px"
        >
          <div style="width: 45%" class="name">{{ item.merchantName }}</div>
          
          <div style="width: 45%" v-html="item.message"></div>
          <div
            style="
              width: 10%;
              text-align: center;
              width: 25px;
              height: 25px;
              line-height: 25px;
            "
            :style="{
              color:
                item.alarmLevel == 1
                  ? '#FFDC18'
                  : item.alarmLevel == 2
                  ? '#FE712C'
                  : '#FF4343'
            }"
            class="level"
          >
            {{
              item.alarmLevel == 1 ? "低" : item.alarmLevel == 2 ? "中" : "高"
            }}
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
    <div class="empty-text" v-else>暂无数据</div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { alarm } from "@/api/index";
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    // 这里存放数据
    return {
      areaCode: "",
      listData: [],
      time_getalarm: ""
    };
  },
  // 监听属性 类似于data概念
  computed: {
    defaultOption() {
      return {
        step: 0, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 42, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  methods: {
    getalarm(areaCode) {
      alarm(areaCode).then(res => {
        // const data = res.data.map(item => {
        //   let str = item.message.split(item.message.replace(/[^0-9]/gi, ""));
        //   item.message =
        //     str[0] +
        //     '<span style="color:#FFFC00">' +
        //     item.message.replace(/[^0-9]/gi, "") +
        //     "</span>" +
        //     str[1];
        //   return item;
        // });
        this.listData = res.data;
      });
    }
  },
  mounted() {
    this.getalarm(this.areaCode);
    this.time_getalarm = setInterval(() => {
      this.getalarm(this.areaCode);
    }, 1000 * 60 * 5);
  },
  beforeDestroy() {
    clearInterval(this.time_getalarm);
  },
  watch: {
    "$store.state.areaCode"(newval, olval) {
      if (newval != olval) {
        this.areaCode = newval;
        this.getalarm(this.areaCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box2 {
  height: calc(40vh - 85px);
}
.table-flex {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.empty-text {
  height: calc(40vh - 15vh);
  display: flex;
  align-items: center;
  justify-content: center;
}
.list {
  height: calc(40vh - 16.5vh);
  overflow: hidden;
  font-size: 0.875rem;
}
.table-list-item {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-list-item:nth-child(even) {
  border-radius: 5px;
  background-color: rgba($color: #1a263e, $alpha: 0.8);
  padding: 0 10px;
}
.table-list-item:nth-child(odd) {
  border-radius: 5px;
  padding: 0 10px;
}
.level {
  border: 1px solid #2f4266;
  border-radius: 4px;
}
</style>
